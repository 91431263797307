.slideshows {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .card-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* width: 60%; */
  }
  
  .card {
    margin: 10px 10px 10px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #ffffff;
  }

  .card2 {
    margin: 10px 10px 10px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #ffffff;
  }
  .bu_n{
    background-color: #3EC63B;
  }
  
  .controls {
    margin-top: 30%;
    right: 45%;
    position: absolute;
  }

  .med_img1{
    height: 100%;
    width: 100%;
    position: relative;
  }
  
  .controls button {
    margin: 0 5px;
    padding: 5px 10px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .controls button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .pro_ids,.pro_pz{
    text-align: left;
    margin-left: 20px;
    font-size: x-small;
  }

  .price-details {
    display: flex;
    align-items: center;
  }
  
  .pro_pz.original-price {
    text-decoration: line-through;
    color: red;
    margin-right: 10px;
  }
  
  .pro_pz.discounted-price {
    color: green;
  }
  
  